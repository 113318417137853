<template>
  <b-nav-item-dropdown
    class="dropdown-cart mr-25"
    menu-class="dropdown-menu-media"
    right
    @show="fetchItems"
  >
    <template #button-content>
      <feather-icon
        class="text-body"
        icon="ShoppingCartIcon"
        size="28"
        :badge="items.length"
        badge-classes="badge-warning"
      />
    </template>

    <!-- Header -->
    <li class="dropdown-menu-header">
      <div class="dropdown-header d-flex">
        <h4 class="notification-title mb-0 mr-auto">
          My Cart
        </h4>
        <b-badge
          pill
          variant="light-warning"
        >
          <span v-if="!isFromLanding">{{ this.$store.state.checkout.itemCount }} Items</span>
          <span v-if="isFromLanding">0 Items</span>
        </b-badge>
      </div>
    </li>

    <!-- Cart Items -->
    <vue-perfect-scrollbar
      v-if="items.length"
      :settings="perfectScrollbarSettings"
      class="scrollable-container media-list scroll-area"
      tagname="li"
    >
      <b-media
        v-for="item in items"
        :key="item.key"
      >
        <template #aside>
          <b-img
            :src="item.image"
            :alt="item.name"
            rounded
            width="62px"
          />
        </template>

        <feather-icon
          icon="XIcon"
          class="cursor-pointer cart-item-remove"
          @click.stop="removeItemFromCart(item)"
        />

        <div class="media-heading">
          <h6 class="cart-item-title">
            <b-link class="text-body">
              {{ item.name }}
            </b-link>
          </h6>
        </div>

        <div class="cart-item-qty ml-1">
          <b-form-spinbutton
            v-model="item.quantity"
            min="1"
            size="sm"
            @change="updateQty($event, item)"
          />
        </div>

        <h5 class="cart-item-price p-1">
          &#8369;{{ totalItemAmount(item.price, item.quantity) }}
        </h5>
      </b-media>
    </vue-perfect-scrollbar>

    <!-- Cart Footer -->
    <li
      v-if="items.length"
      class="dropdown-menu-footer"
    >
      <div class="d-flex justify-content-between mb-1">
        <h6 class="font-weight-bolder mb-0">
          Total:
        </h6>
        <h6 class="text-primary font-weight-bolder mb-0">
          &#8369;{{ totalAmount }}
        </h6>
      </div>
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        block
        :to="{ name: 'cart-checkout' }"
      >
        Checkout
      </b-button>
    </li>

    <p
      v-if="!items.length"
      class="m-0 p-1 text-center"
    >
      Your cart is empty
    </p>
  </b-nav-item-dropdown>
</template>

<script>
import {
  BNavItemDropdown, BBadge, BMedia, BLink, BImg, BFormSpinbutton, BButton,
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import Ripple from 'vue-ripple-directive'
import Cart from '../../libs/controllers/cart-control'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  props: {
    isFromLanding: Boolean
  },
  components: {
    BNavItemDropdown,
    BBadge,
    BMedia,
    BLink,
    BImg,
    BFormSpinbutton,
    VuePerfectScrollbar,
    BButton,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      items: [],
      perfectScrollbarSettings: {
        maxScrollbarLength: 60,
        wheelPropagation: false,
      },
      cart: new Cart,
      bouncedRequest: null
    }
  },
  computed: {
    totalAmount() {
      let total = 0
      this.items.forEach(i => { total += (i.price * i.quantity) })
      return total.toFixed(2)
    },
  },
  created() {
    // console.log('Cart Dropdown created')
    if (!this.isFromLanding) {
      setTimeout(() => {
        this.fetchItems()
      }, 100)
    }
  },
  destroyed() {
    clearTimeout(this.bouncedRequest)
  },
  mounted() {
  },
  methods: {
    fetchItems() {
      if (!this.isFromLanding) {
        this.$store.dispatch('getCartFromWeb').then(response => {
          if (response) {
            this.getItems()
          }
          else {
            console.log(response)
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Cannot get Cart right now',
                icon: 'ShoppingCartIcon',
                variant: 'danger',
                text: response.message
              }
            })
          }
        }).catch(ex => {
          console.log('Error dispatching getCartFromWeb: ', ex)
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Cannot get Cart right now',
              icon: 'ShoppingCartIcon',
              variant: 'danger',
              text: ex
            }
          })
        })
      }
    },
    getItems() {
      this.items = this.$store.getters.getCart
    },
    totalItemAmount(base, qty) {
      let amt = parseFloat(base) * parseInt(qty)
      return amt
    },
    updateQty(qty, item) {
      // item.qty = qty
      // this.cart.updateCart(item, true)

      // Delay request for 300ms before firing.
      // This is to prevent clickjacking the spinbutton.
      if (this.bouncedRequest) {
        clearTimeout(this.bouncedRequest)
      }
      var data = {id: item.id, quantity: qty}
      this.bouncedRequest = setTimeout(() => {
        this.$store.dispatch('updateItemInCart', data).then(response => {
          let res = response.data
          if (res.result) {
            console.log(res.message)
          }
          else {
            console.log('Error updating item qty: ', response)
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Failed to adjust item quantity',
                icon: 'ShoppingCartIcon',
                variant: 'danger',
                text: res.message
              }
            })
          }
        }).catch(ex => {
          console.log('Error dispatching updateItemInCart: ', ex)
        })
      }, 300)
    },
    removeItemFromCart(item) {
      let data = {id: item.id}
      this.$store.dispatch('removeItemFromCart', data).then(response => {
        let res = response.data
        if (res.result) {
          const itemIndex = this.items.findIndex(p => p.id === item.id)
          this.items.splice(itemIndex, 1)
        }
        else {
          console.log('Error removing item from cart: ', res.message)
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
                title: 'Failed to remove item from cart',
                icon: 'ShoppingCartIcon',
                variant: 'danger',
                text: res.message
            },
          })
        }
      }).catch(ex => {
        console.log('Error dispatching removeItemFromCart: ', ex)
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.dropdown-cart {
  .media {
    .media-aside {
      align-items: center;
    }
  }
}
</style>
